import { useQueryClient } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useEffect } from "react";
import { DateRangeSelector } from "src/components/date/dashboard/date-range-selector";
import { PageContainer } from "src/components/page-container";
import { queryKeys } from "src/config/query-key";
import { usePageView } from "src/hooks/use-page-view";
import { useWorkspaces } from "src/hooks/use-workspaces";
import { WorkspaceDashboard } from "src/sections/dashboard/workspace/workspace-dashboard";
import { useThumbzApi } from "src/services/thumbz-api";

export const Route = createFileRoute("/_authenticated/dashboard")({
  component: () => <Page />,
});

const Page = () => {
  usePageView();

  const queryClient = useQueryClient();
  const { data } = useWorkspaces();
  const { api } = useThumbzApi();

  useEffect(() => {
    if (data) {
      for (const { wrk_id } of data) {
        queryClient.prefetchQuery({
          queryKey: queryKeys.workspace.get({ wrk_id }).queryKey,
          queryFn: () => api.workspace.workspaceControllerGet(wrk_id),
        });
      }
    }
  }, [api.workspace, data, queryClient]);

  return (
    <PageContainer
      title={"Dashboard da organização"}
      subtitle="Visualize métricas e acompanhe atividades dos workspaces da organização."
      leftNode={<DateRangeSelector />}
    >
      <WorkspaceDashboard wrk_id={undefined} />
    </PageContainer>
  );
};
