import React, { useEffect, useState } from "react";
import { ColDef } from "ag-grid-community";
import {
  IconButton,
  Stack,
  Popover,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useThumbzApi } from "src/services/thumbz-api";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "src/config/query-key";
import toast from "react-hot-toast";
import { Archive, Restore, MoreVertOutlined } from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";
import { AgGridReactDefault } from "src/components/table/custom-table";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { IApproval,  IApprovalListResponseDto,
  IApprovalStageCustomer,
  IStatusType,} from "src/services/thumbz-base-api";
import { useAppSelector } from "src/app/hooks"; 
import { organizationSlice } from "src/features/organization/organizationSlice"; 
import { format } from "date-fns";
import { ptBR } from "date-fns/locale/pt-BR";
  
interface WorkspaceArchivedProps {
  wrk_id: string;
  org_id: string;
}

interface Approval {
  apv_id: string;
  apv_name: string;
  apv_created_at: string;
  apv_updated_at: string;
  apv_archived: boolean;
  wrk_id: string;
  wrk_name: string;
}

export const WorkspaceArchived: React.FC<WorkspaceArchivedProps> = ({ wrk_id }) => {
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { api } = useThumbzApi();

  const { org_id } = useAppSelector(organizationSlice.selectors.selectedOrganization);

  const unarchiveApprovalMutation = useMutation({
    mutationFn: (apv_id: string) => api.approval.approvalControllerUnarchiveApproval({ apv_id }),
    onSuccess: () => {
      toast.success("Aprovação desarquivada com sucesso");
      queryClient.invalidateQueries(queryKeys.approval.list({ wrk_id, archived: false }));
      queryClient.invalidateQueries(queryKeys.approval.list({ wrk_id, archived: true }));
    },
    onError: (error, apv_id) => {
      console.error(`❌ Erro ao desarquivar aprovação: ${apv_id}`, error);
      toast.error("Erro ao desarquivar aprovação. Tente novamente.");
    },
  });

  const approvalsQuery = useQuery({
    queryKey: queryKeys.approval.list({ wrk_id, archived: true }).queryKey,
    queryFn: async () => {
      try {
        const response = await api.approval.approvalControllerList({ wrk_id, org_id, archived: true });
        return Array.isArray(response) ? response : [];
      } catch (error) {
        toast.error("Erro ao carregar aprovações arquivadas. Tente novamente.");
        return [];
      }
    },
  });

  const formatDateToLocalTimezone = (date: string | Date, formatStr: string): string => {
    if (!date) return "Invalid Date";
    const utcDate = new Date(date);
    const saoPauloDate = new Date(utcDate.getTime() - 3 * 60 * 60 * 1000);
    return format(saoPauloDate, formatStr, { locale: ptBR });
  };

  const handleUnarchive = (event: React.MouseEvent<HTMLElement>, apv_id: string) => {
    event.stopPropagation();
    
    confirm({
      title: "Desarquivar?",
      description: `Tem certeza que deseja desarquivar esta aprovação?`,
      confirmationText: "Sim, desarquivar",
      cancellationText: "Cancelar",
      confirmationButtonProps: { color: "primary" },
    }).then(() => {
      unarchiveApprovalMutation.mutate(apv_id);
    });
  };

  const rowData: Approval[] = approvalsQuery.data?.map((approval) => ({
    apv_id: approval.apv_id,
    apv_name: approval.apv_name,
    apv_created_at: formatDateToLocalTimezone(approval.apv_created_at, "dd/MM/yyyy HH:mm"),
    apv_updated_at: approval.apv_updated_at
      ? formatDateToLocalTimezone(approval.apv_updated_at, "dd/MM/yyyy HH:mm")
      : "Sem atualização",
    apv_archived: approval.apv_archived ?? false,
    wrk_id: approval.wrk_id ?? "Desconhecido",
    wrk_name: approval.wrk_name ?? "Sem Workspace",
  })) ?? [];
  
  const colDefs: ColDef<Approval>[] = [
    { headerName: "Nome da Aprovação", field: "apv_name", flex: 1 },
    { 
      headerName: "Data Criação", 
      field: "apv_created_at", 
      flex: 1 
    },
    { 
      headerName: "Última Atualização", 
      field: "apv_updated_at", 
      flex: 1 
    },
    { 
      headerName: "Nome do Workspace", 
      field: "wrk_name", 
      flex: 1 
    },
    {
      headerName: "Ações",
      pinned: "right",
      width: 100,
      cellRenderer: (params: { data: Approval }) => (
        <PopupState variant="popover" popupId={`popup-menu-${params.data.apv_id}`}>
          {(popupState) => (
            <div>
              <IconButton {...bindTrigger(popupState)}>
                <MoreVertOutlined />
              </IconButton>
              <Popover {...bindPopover(popupState)}>
                <Menu
                  open={popupState.isOpen} 
                  anchorEl={popupState.anchorEl}
                  onClose={(event, reason) => popupState.close()}
                >
                  {params.data.apv_archived && (
                    <MenuItem
                    onClick={(event) => handleUnarchive(event, params.data.apv_id)}
                    disabled={unarchiveApprovalMutation.isPending} 
                  >
                    <ListItemIcon>
                      <Restore fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{unarchiveApprovalMutation.isPending ? "Desarquivando..." : "Desarquivar"}</ListItemText>
                  </MenuItem>
                  )}
                </Menu>
              </Popover>
            </div>
          )}
        </PopupState>
      ),
    },
  ];

  return (
    <Stack spacing={2}>
    <AgGridReactDefault<Approval>
      key={"archive-table"}
      rowData={
        approvalsQuery.data?.map((approval) => ({
          apv_id: approval.apv_id,
          apv_name: approval.apv_name,
          apv_created_at: formatDateToLocalTimezone(approval.apv_created_at, "dd/MM/yyyy HH:mm"),
          apv_updated_at: approval.apv_updated_at
            ? formatDateToLocalTimezone(approval.apv_updated_at, "dd/MM/yyyy HH:mm")
            : "Sem atualização",
          apv_archived: approval.apv_archived ?? false,
          wrk_id: approval.wrk_id ?? "Desconhecido",
          wrk_name: approval.wrk_name ?? "Sem Workspace",
        })) ?? []
      }
      columnDefs={colDefs}
      rowSelection="single"
    />
  </Stack>
  );
};


