import React from "react";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { IApproval } from "src/services/thumbz-base-api";
import { CardActions, CardContent, CardHeader } from "@mui/material";
import { useSelector } from "react-redux";
import { approvalCreateSlice } from "src/features/approvalsCreate/approvalCreateSlice";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "src/config/query-key";
import { useThumbzApi } from "src/services/thumbz-api";
import { useMounted } from "src/hooks/use-mounted";
import { RevisionDetails } from "./approval-revision-sections/revision-details";
import { RevisonsItems } from "./approval-revision-sections/revisons-items";
import { RevisionStages } from "./approval-revision-sections/revision-stages";
import { EmptyPlaceholder } from "../components/EmptyPlaceholder";
import { RevisionNotifications } from "./approval-revision-sections/revision-notifications";
import { RevisionStart } from "./approval-revision-sections/revision-start";
import { RevisionComplement } from "./approval-revision-sections/revision-complement";

interface IApprovalRevisionProps {
  approval: IApproval;
}

export const ApprovalRevision: React.FC<IApprovalRevisionProps> = (props) => {
  const isMounted = useMounted();

  const { api } = useThumbzApi();
  const approvalItems = useSelector(approvalCreateSlice.selectors.selectApprovalItems);

  const approvalQuery = useQuery({
    queryKey: queryKeys.approval?.approvalControllerGet(props.approval?.apv_id).queryKey,
    queryFn: () => api.approval?.approvalControllerGet(props.approval?.apv_id),
    enabled: !!props.approval?.apv_id && isMounted(),
  });

  const approval = approvalQuery.data;

  if (!approval) {
    return <EmptyPlaceholder overlineText="Aprovação não encontrada" buttonLink="/workspaces" />;
  }

  return (
    <Stack spacing={2}>
      <CardHeader
        title="Revisão da Aprovação"
        subheader="Verifique as informações da aprovação antes de enviar para os aprovadores."
        sx={{ pb: 0, mb: 0, pt: 0, mt: 0 }}
      />
      <CardContent sx={{ pt: 0 }}>
        <Stack spacing={1}>
          <Card>
            <CardContent>
              <RevisionDetails approval={approval} />
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <RevisonsItems approvalItems={approvalItems} />
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <RevisionStages approval={approval} />
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <RevisionComplement approval={approval} />
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <RevisionNotifications approval={approval} />
            </CardContent>
          </Card>

          <CardActions>
            <RevisionStart approval={approval} />
          </CardActions>
        </Stack>
      </CardContent>
    </Stack>
  );
};
