import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum TabValue {
  Dashboard = "dashboard",
  Approvals = "approvals",
  Archived = "archived",
  Approvers = "approvers",
  Flow = "flow",
  Details = "details",
  Audit = "audit",
}

interface LayoutState {
  sidenav: {
    default_size: number;
    width: number;
    min_size: number;
    max_size: number;
  };
  workspace: {
    tabs: {
      selected: TabValue;
      options: {
        label: string;
        value: TabValue;
      }[];
    };
  };
}

const initialState: LayoutState = {
  sidenav: {
    default_size: 280,
    min_size: 200,
    width: 280,
    max_size: 500,
  },
  workspace: {
    tabs: {
      selected: TabValue.Approvals,
      options: [
        { label: "Dashboard", value: TabValue.Dashboard },
        { label: "Aprovações", value: TabValue.Approvals },
        { label: "Arquivadas", value: TabValue.Archived },
        { label: "Aprovadores", value: TabValue.Approvers },
        { label: "Modelos", value: TabValue.Flow },
        { label: "Detalhes", value: TabValue.Details },
        { label: "Histórico", value: TabValue.Audit },
      ],
    },
  },
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    resizeSidenav: (state, action: PayloadAction<number>) => {
      if (action.payload < state.sidenav.min_size) {
        state.sidenav.width = state.sidenav.min_size;
        return;
      }
      if (action.payload > state.sidenav.max_size) {
        state.sidenav.width = state.sidenav.max_size;
        return;
      }

      state.sidenav.width = action.payload;
    },

    // Workspace -> Tabs
    selectTab: (state, action: PayloadAction<TabValue>) => {
      state.workspace.tabs.selected = action.payload;
    },
  },
  selectors: {
    sidenavWidth: (state: LayoutState) => state.sidenav.width,
    sidenavMinSize: (state: LayoutState) => state.sidenav.min_size,
    sidenavMaxSize: (state: LayoutState) => state.sidenav.max_size,
    defaultSidenavSize: (state: LayoutState) => state.sidenav.default_size,

    // Workspace -> Tabs
    workspaceTabs: (state: LayoutState) => state.workspace.tabs.options,
    selectedTab: (state: LayoutState) => state.workspace.tabs.selected,
  },
  extraReducers: (builder) => {},
});

export const layoutThunks = {};
