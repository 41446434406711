import { useQueryClient } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useEffect } from "react";
import { PageContainer } from "src/components/page-container";
import { queryKeys } from "src/config/query-key";
import { usePageView } from "src/hooks/use-page-view";
import { useWorkspaces } from "src/hooks/use-workspaces";
import { ApprovalViewSelector } from "src/sections/approvals/approval-view-selector";
import { useThumbzApi } from "src/services/thumbz-api";

export const Route = createFileRoute("/_authenticated/")({
  component: () => <Page />,
});

const Page = () => {
  usePageView();

  const queryClient = useQueryClient();
  const { data } = useWorkspaces();
  const { api } = useThumbzApi();

  useEffect(() => {
    if (data) {
      for (const { wrk_id } of data) {
        queryClient.prefetchQuery({
          queryKey: queryKeys.workspace.get({ wrk_id }).queryKey,
          queryFn: () => api.workspace.workspaceControllerGet(wrk_id),
        });
      }
    }
  }, [api.workspace, data, queryClient]);

  return (
    <PageContainer
      title={"Visão Geral"}
      subtitle="Acompanhe o desempenho da organização e seus workspaces."
    >
      <ApprovalViewSelector
        enableCreate={false}
        wrk_id={undefined}
        config={{
          show_apv_name: true,
          show_stages: true,
          show_workspace: true,
          show_apv_created_at: false,
        }}
      />
    </PageContainer>
  );
};
