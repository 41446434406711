import { Refresh } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, AlertTitle, Box, Button, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useOrganizationUsage } from "src/hooks/use-organization-usage";
import {
  IOrganizationUsageResponse,
  IUsage,
  IUsageSeverityEnum,
} from "src/services/thumbz-base-api";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";
import { Link } from "src/utils/router-link";
import { Stack } from "@mui/system";

type AvailableUsageTypes = keyof Omit<IOrganizationUsageResponse, "org_id">;

interface OrganizationUsageProps {
  type: AvailableUsageTypes;
  show_when?: {
    [key in "warning" | "error" | "success" | "info"]: boolean;
  };
}

export const OrganizationUsage: React.FC<OrganizationUsageProps> = ({ type, show_when }) => {
  const { data, query } = useOrganizationUsage();
  const { error = true, info = true, success = true, warning = true } = show_when || {};

  const usageType = useMemo(() => {
    let predescription = `Você será cobrado pelo número de Workspaces que usar apenas, a partir de R$9,00/unidade.`;
    let title = "Cota de uso";
    let translatedType: string = "";
    let verb: string = "";
    let usage: IUsage | undefined = undefined;
    let consequence =
      "Seus serviços podem ser interrompidos se você exceder os limites do seu plano";

    switch (type) {
      case "collaborators":
        usage = data?.collaborators;
        title = "Cota de uso de Workspaces";
        translatedType = "colaboradores";
        verb = "convidou";
        break;
      case "workspaces":
        usage = data?.workspaces;
        title = "Pague somente pelo que precisar!";
        translatedType = "workspaces";
        verb = "criou";
        consequence =
          "Você não pode criar novos workspaces até que você atualize seu plano, atualize agora";
        break;
      default:
        break;
    }

    let description = `Você ${verb} ${usage?.used} ${translatedType} de ${usage?.limit} (${usage?.amount_percentage_string})`;

    if (usage?.severity === "error") {
      if (type === "collaborators") {
        consequence =
          "Você não pode adicionar novos colaboradores até que atualize seu plano, atualize agora";
      }
    }

    {/* 
    if (usage?.severity === "warning") {
      if (type === "collaborators") {
        consequence =
          "Você pode não conseguir adicionar novos colaboradores se exceder os limites do seu plano, considere atualizar";
      }
    }*/}

    if (type === "workspaces") {
      description = `Você ${verb} ${usage?.used} ${translatedType} de ${usage?.limit} (${usage?.amount_percentage_string})`;
    }

    return {
      ...usage,
      show_warning: usage?.show_warning || false,
      amount_percentage_color: usage?.amount_percentage_color || "info",
      amount_percentage_string: usage?.amount_percentage_string || "",
      amount_percentage_value: usage?.amount_percentage_value || 0,
      limit: usage?.limit || 0,
      remaining: usage?.remaining || 0,
      severity: usage?.severity,
      used: usage?.used || 0,
      title,
      //description,
      consequence,
      predescription,
    };
  }, [data, type]);

  if (query.isLoading || usageType === undefined || usageType === null) {
    return <Skeleton variant="rectangular" height={100} />;
  }

  if (usageType.severity === "error" && !error) return null;
  if (usageType.severity === "info" && !info) return null;
  if (usageType.severity === "success" && !success) return null;
  if (usageType.severity === "warning" && !warning) return null;

  if (query.isError) {
    return (
      <Alert
        severity={"error"}
        action={
          <LoadingButton
            loading={query.isFetching}
            loadingPosition="center"
            onClick={() => {
              query.refetch();
            }}
            color="inherit"
            size="small"
          >
            <Refresh />
          </LoadingButton>
        }
      >
        <AlertTitle>Erro</AlertTitle>
        {query.error?.message || "Ocorreu um erro ao buscar o uso da organização"}
      </Alert>
    );
  }

  const warningOrDanger =
    usageType.severity === IUsageSeverityEnum.Warning || usageType.severity === "error";

  const showUpgradeLink =
    usageType.severity === IUsageSeverityEnum.Warning || usageType.severity === "error";

  return (
    <Alert
      severity={usageType.severity}
      action={
        showUpgradeLink ? (
          <Link
            color="text.primary"
            component={RouterLink}
            to={"/organizacao/faturamento"}
            variant="subtitle2"
          >
            <Button variant="contained" size="small">
              Atualizar agora
            </Button>
          </Link>
        ) : null
      }
    >
      <AlertTitle>{usageType.title}</AlertTitle>
      <Stack>
        <Typography variant="body2">{usageType.predescription}</Typography>
        {/*<Typography variant="body2">{usageType.description}</Typography>*/}
        <Typography variant="body2">
          {warningOrDanger && <Box mt={1}>{usageType?.consequence}</Box>}
        </Typography>
      </Stack>
    </Alert>
  );
};
