import React, { useState, useEffect, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTheme } from "@mui/material/styles";
import {
  IApprovalListResponseDto,
  IApprovalStageCustomer,
  IStatusType,
} from "src/services/thumbz-base-api";
import { ApprovalViewProps } from "./approval-view-selector";
import { queryKeys } from "src/config/query-key";
import { useThumbzApi } from "src/services/thumbz-api";
import {
  Card,
  CardHeader,
  Typography,
  Grid,
  Box,
  Stack,
  AvatarGroup,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { RenderAvatar } from "src/components/avatar/RenderAvatar";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useNavigate } from "@tanstack/react-router";
import { MoreVert } from "@mui/icons-material";
import toast from "react-hot-toast";
import { useConfirm } from "material-ui-confirm";

interface KanbanCardData extends IApprovalListResponseDto {}

const formatDateToLocalTimezone = (date: string | Date, formatStr: string): string => {
  if (!date) return "Invalid Date";
  const utcDate = new Date(date);
  const saoPauloDate = new Date(utcDate.getTime() - 3 * 60 * 60 * 1000);
  const formattedDate = format(saoPauloDate, formatStr, { locale: ptBR });
  return formattedDate;
};

export const ApprovalKanban: React.FC<ApprovalViewProps> = ({ onDelete, approvals, config, org_id, refetchApprovals }) => {
  const navigate = useNavigate();
  const { api } = useThumbzApi();
  const theme = useTheme(); 
  const [columns, setColumns] = useState<IStatusType[]>([]);
  const confirm = useConfirm();
  const kanbanData: KanbanCardData[] = approvals.filter((approval) => !approval.apv_archived);
  const statusQuery = useQuery({
    queryKey: queryKeys.status.statusControllerFindAll().queryKey,
    queryFn: () => api.status.statusControllerFindAll(),
  });

  useEffect(() => {
    if (statusQuery.data) {
      setColumns(statusQuery.data);
    }
  }, [statusQuery.data]);

  function handleCardClick({ apv_id, wrk_id }: { apv_id: string; wrk_id: string }) {
    navigate({
      to: `/workspaces/$wrk_id/aprovacao/$apv_id`,
      params: { wrk_id, apv_id },
    });
  }
   
  const KanbanCard: React.FC<KanbanCardData> = (approval) => {
    const {
      apv_id,
      apv_name,
      apv_created_at,
      wrk_id,
      wrk_photo_url,
      apv_status,
      flow_name,
      wrk_name,
    } = approval;
    const { show_apv_created_at, show_apv_name, show_stages, show_workspace } = config;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const queryClient = useQueryClient();
    
    const allCustomers: IApprovalStageCustomer[] = [];

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      handleMenuClose();
      onDelete({ apv_id, wrk_id });
    };
    
    const handleArchive = (event: React.MouseEvent<HTMLElement>, apv_id: string) => {
      event.stopPropagation();
      handleMenuClose();
    
      confirm({
        title: "Arquivar?",
        description: `Tem certeza que deseja arquivar a aprovação?`,
        confirmationText: "Sim, arquivar",
        cancellationText: "Cancelar",
        confirmationButtonProps: { color: "error" },
      }).then(() => {
        archiveApprovalMutation.mutate(apv_id);
      });
    };
    
    const archiveApprovalMutation = useMutation({
      mutationFn: (apv_id: string) => api.approval.approvalControllerArchiveApproval({ apv_id }),
      onSuccess: () => {
        toast.success("Aprovação arquivada com sucesso");
        refetchApprovals();
        queryClient.invalidateQueries(queryKeys.approval.list({ wrk_id, archived: false }));
        queryClient.invalidateQueries(queryKeys.approval.list({ wrk_id, archived: true }));
      },
      onError: () => {
        toast.error("Erro ao arquivar aprovação. Tente novamente.");
      },
    });
    
    return (
      <Card
        variant="elevation"
        elevation={3}
        onClick={() => {
          // Only redirects if the menu is NOT open
          if (!anchorEl) {
            handleCardClick({ apv_id, wrk_id });
          }
        }}
        sx={{
          cursor: "pointer",
          "&:hover": {
            boxShadow: theme.shadows[12],
          },
          position: "relative",
        }}
      >
        <Stack spacing={1} p={2}>
          <CardHeader
            sx={{ p: 0 }}
            title={
              show_apv_name && (
                <Tooltip title={apv_name} arrow>
                  <Typography
                    variant="subtitle1"
                    noWrap
                    sx={{
                      display: "block",
                      width: "12.5rem", 
                      maxWidth: "min(80%, 15rem)", 
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      bottom: "0.5rem",
                    }}
                  >
                    {apv_name}
                  </Typography>
                </Tooltip>
              )
            }
            subheader={
              show_stages && (
                <Stack spacing={0.5} pt={1} sx={{ maxWidth: "100%" }}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      fontSize: { xs: "0.75rem", sm: "0.875rem", md: "1rem" }, 
                      whiteSpace: "normal", 
                      wordBreak: "break-word", 
                      lineHeight: 1.2,
                    }}
                  >
                    Criado em: {formatDateToLocalTimezone(apv_created_at, "dd/MM/yyyy")} às{" "}
                    {formatDateToLocalTimezone(apv_created_at, "HH:mm")} horas
                  </Typography>
                </Stack>
              )
            }
          />
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              handleMenuOpen(event);
            }}
            sx={{
              position: "absolute",
              top: 0,
              right: 8,
              zIndex: 1,
            }}
          >
            <MoreVert />
          </IconButton>

          <Menu 
            anchorEl={anchorEl} 
            open={Boolean(anchorEl)} 
            onClose={handleMenuClose}
            onMouseDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
          >
            <MenuItem 
              onClick={(event) => {
                event.stopPropagation();
                handleDelete(event);
              }}
            >
              Excluir
            </MenuItem>
            <MenuItem 
              onClick={(event) => {
                event.stopPropagation();
                handleArchive(event, apv_id);
              }}
            >
              Arquivar
            </MenuItem>
          </Menu>
         
          {show_stages && (
            <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
              <AvatarGroup max={50}>
                {allCustomers.map(({ customer }) => (
                  <RenderAvatar
                    key={`avatar-${apv_id}-${customer.cust_id}`}
                    src={customer.cust_photo?.ast_url}
                    variant="circular"
                    sx={{ width: 25, height: 25 }}
                    fallbackSize={15}
                  />
                ))}
              </AvatarGroup>
            </Stack>
          )}

          {show_workspace && (
            <Stack direction="row" spacing={1}>
              <RenderAvatar
                key={`workspace-${wrk_id}`}
                src={wrk_photo_url}
                variant="circular"
                sx={{ width: 25, height: 25 }}
                fallbackSize={25}
              />
              <Typography variant="body2" color="textSecondary" noWrap>
                {wrk_name}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Card>
    );
  };

  const KanbanColumn: React.FC<{ title: string; cards: KanbanCardData[] }> = ({ title, cards }) => {
    const theme = useTheme();
    const [visibleCount, setVisibleCount] = useState(5);
    const loadMoreRef = useRef<HTMLDivElement | null>(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting && visibleCount < cards.length) {
            setVisibleCount((prev) => Math.min(prev + 5, cards.length)); 
          }
        },
        { root: null, rootMargin: "200px", threshold: 0.1 } 
      );
  
      if (loadMoreRef.current) observer.observe(loadMoreRef.current);
  
      return () => observer.disconnect(); 
    }, [visibleCount, cards.length]); 
  
    return (
      <Stack
        bgcolor={theme.palette.divider}
        p={2}
        borderRadius={1}
        flexGrow={1}
        maxHeight="500px" 
        overflow="auto" 
      >
        <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="body1" fontWeight="bold">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            ({cards.length})
          </Typography>
        </Stack>
        <Stack spacing={2}>
          {cards.slice(0, visibleCount).map((card) => (
            <KanbanCard key={`card-${card.apv_id}`} {...card} />
          ))}
        </Stack>
        {visibleCount < cards.length && <div ref={loadMoreRef} style={{ height: "1px" }} />}
      </Stack>
    );
  };
  

  return (
    <Grid container spacing={2} justifyContent="start" alignItems="stretch">
      {columns
        .sort((a, b) => a.stp_order - b.stp_order)
        .map((column) => (
          <Grid
            item
            xs={3}
            key={column.stp_id}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <KanbanColumn
              title={column.stp_label}
              cards={kanbanData.filter((card) => card.stp_type === column.stp_type)}
            />
          </Grid>
        ))}
    </Grid>
  );
};