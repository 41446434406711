import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/system";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import { useRouter } from "@tanstack/react-router";
import { useConfirm } from "material-ui-confirm";
import React from "react";
import toast from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { queryKeys } from "src/config/query-key";
import { approvalCreateSlice } from "src/features/approvalsCreate/approvalCreateSlice";
import { useThumbzApi } from "src/services/thumbz-api";
import { IApproval, IStatusTypeStpTypeEnum } from "src/services/thumbz-base-api";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";

interface IRevisionStartProps {
  approval: IApproval;
}

export const RevisionStart: React.FC<IRevisionStartProps> = ({ approval }) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const router = useRouter();
  const { api } = useThumbzApi();
  const statusParams = { wrk_id: approval?.workspace.wrk_id };
  const statusQuery = useQuery({
    queryKey: queryKeys.status.statusControllerFindByWorkspace(statusParams).queryKey,
    queryFn: () => api.status.statusControllerFindByWorkspace(statusParams.wrk_id),
  });
  const changeStatusMutation = useMutation({
    mutationFn: api.approval.approvalControllerChangeStatus,
    onError: (error) => {
      dispatch(approvalCreateSlice.actions.updateStageStatus({ stage: "4", status: "failed" }));
      return getServerErrorArrayMessage(error).forEach((message) => toast.error(message));
    },
    onMutate: ({ apv_id, sts_id }) => {
      dispatch(approvalCreateSlice.actions.updateStageStatus({ stage: "4", status: "loading" }));
    },
    onSuccess: (response) => {
      const { apv_id, workspace } = response;
      const queryKey = queryKeys.approval.approvalControllerGet(apv_id).queryKey;
      queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes("approval") });
      queryClient.setQueryData(queryKey, response);
      dispatch(approvalCreateSlice.actions.reset());
      dispatch(approvalCreateSlice.actions.updateStageStatus({ stage: "4", status: "succeeded" }));

      return router.navigate({
        to: "/workspaces/$wrk_id/aprovacao/$apv_id/detalhes",
        params: { wrk_id: workspace.wrk_id, apv_id },
        replace: true,
      });
    },
    onSettled: () => {
      dispatch(approvalCreateSlice.actions.updateStageStatus({ stage: "4", status: "idle" }));
    },
  });

  const allStatus = statusQuery.data ?? [];
  const isLoading = useAppSelector(approvalCreateSlice.selectors.hasAnyStageLoading);
  const confirm = useConfirm();

  function handleChangeStatus() {
    const inReview = allStatus.find(
      ({ statusType }) => statusType.stp_type === IStatusTypeStpTypeEnum.IN_REVIEW,
    );
    if (!inReview) {
      toast.error(`Não foi possível trocar o status da aprovação ${approval?.apv_name}`);
      return;
    }

    changeStatusMutation.mutate({
      apv_id: approval?.apv_id,
      sts_id: inReview.sts_id,
    });
  }

  function onStartApproval() {
    confirm({
      title: "Deseja iniciar a aprovação?",
      description:
        "Ao iniciar a aprovação, os aprovadores receberão a notificação para aprovar e você não poderá mais editar a aprovação.",
      confirmationText: "Sim",
      cancellationText: "Não",
    }).then(() => {
      handleChangeStatus();
    });
  }

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" flex={1} flexGrow={1}>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={onStartApproval}
          loading={isLoading || changeStatusMutation.isPending}
        >
          Iniciar Aprovação
        </LoadingButton>
      </Stack>
    </>
  );
};
