import { createFileRoute } from "@tanstack/react-router";
import { useEffect } from "react";
import { usePageView } from "src/hooks/use-page-view";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import {
  approvalEditSlice,
  approvalEditThunks,
} from "src/features/approval-edit/approvalEditSlice";
import ApprovalItemsTable from "src/sections/approval-edit/approval-edit";
import { PageContainerSide } from "src/components/container/page-container-side";
import { ApprovalStageStepper } from "src/sections/approval-edit/approval-stage-stepper";
import { Stack, Typography, Skeleton } from "@mui/material";

export const Route = createFileRoute(
  "/_authenticated/workspaces/$wrk_id/aprovacao/$apv_id/detalhes/",
)({
  component: () => <Page />,
});

const Page = () => {
  usePageView();
  const dispatch = useAppDispatch();
  const approval = useAppSelector(approvalEditSlice.selectors.approval);
  const status = useAppSelector(approvalEditSlice.selectors.status);
  const isLoading = status === "loading";
  const { apv_id, wrk_id } = Route.useParams();

  useEffect(() => {
    dispatch(approvalEditThunks.fetchApproval({ apv_id }));
  }, [apv_id, dispatch]);

  return (
    <PageContainerSide
      title={!isLoading ? approval?.apv_name : "Carregando..."}
      subtitle={
        <Stack>
          {isLoading ? (
            <Skeleton width={120} height={24} />
          ) : (
            <Typography variant="subtitle2">{approval?.apv_status.sts_name}</Typography>
          )}
          {isLoading ? (
            <Skeleton width={180} height={18} />
          ) : (
            <Typography variant="caption" color="textSecondary">
              {approval?.apv_status.statusType.stp_description}
            </Typography>
          )}
        </Stack>
      }
      breadcrumbs={[]}
      leftNode={<ApprovalStageStepper />}
    >
      <ApprovalItemsTable />
    </PageContainerSide>
  );
};

export default Page;
