import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { ApprovalContent } from "src/components/approval/ApprovalContent";
import { EmptyPlaceholder } from "../components/EmptyPlaceholder";
import { DialogController } from "src/hooks/use-dialog";
import { Close } from "@mui/icons-material";
import { AppBar, Toolbar, Typography, IconButton, Slide, DialogTitle } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/app/hooks";
import {
  approvalCreateSlice,
  approvalModalSteps,
} from "src/features/approvalsCreate/approvalCreateSlice";

interface IApprovalModal {
  wrk_id: string;
  dialog: DialogController<{
    apv_id: string;
  }>;
}

export const ApprovalModal: React.FC<IApprovalModal> = ({ wrk_id, dialog }) => {
  const dispatch = useAppDispatch();
  const allSteps = useSelector(approvalCreateSlice.selectors.selectAllSteps);
  const activeStep = useSelector(approvalCreateSlice.selectors.selectActiveStep);
  const isBackEnabled = useSelector(approvalCreateSlice.selectors.selectIsBackActiveEnabled);
  const isNextEnabled = useSelector(approvalCreateSlice.selectors.selectIsNextActiveEnabled);

  const handleNext = () => {
    // dispatch(approvalSlice.actions.nextStep());
  };

  const handleBack = () => {
    dispatch(approvalCreateSlice.actions.backStep());
  };

  return (
    <Dialog
      open={dialog.open}
      onClose={dialog.handleClose}
      fullWidth={true}
      maxWidth={false} // Desabilitar o maxWidth para permitir largura personalizada
      fullScreen={false}
      TransitionComponent={Transition}
      sx={{
        "& .MuiDialog-paper": {
          width: "95vw", // 90% da largura da tela
          height: "95vh", // 90% da altura da tela
          maxWidth: "none", // Desabilitar o limite de largura padrão
          maxHeight: "none", // Desabilitar o limite de altura padrão
        },
      }}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Lorem Ipsum
          </Typography>
          <IconButton edge="start" color="inherit" onClick={dialog.handleClose} aria-label="close">
            <Close />
          </IconButton>
        </Toolbar>
        <Box px={4} mb={2}>
          <Stepper activeStep={activeStep}>
            {approvalModalSteps.map((step) => (
              <Step key={step.value}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </AppBar>
      {dialog.data?.apv_id ? (
        <>
          <DialogContent>
            {/* <ApprovalContent
              wrk_id={wrk_id}
              apv_id={dialog.data?.apv_id}
              activeStep={activeStep}
              onNext={handleNext}
              onBack={handleBack} approval={undefined}            /> */}
            <EmptyPlaceholder
              overlineText="Em breve"
              customSubtitle="Esta funcionalidade estará disponível em breve"
            />
          </DialogContent>

          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ padding: 2 }}>
            <Button disabled={!isBackEnabled} onClick={handleBack}>
              Voltar
            </Button>
            <Button disabled={!isNextEnabled} onClick={handleNext}>
              Próximo
            </Button>
          </Stack>
        </>
      ) : (
        <EmptyPlaceholder
          overlineText="Nenhum fluxo de aprovação selecionado"
          customSubtitle="Selecione um fluxo de aprovação para visualizar"
        />
      )}
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
