import React, { useEffect, useState } from "react";
import { Tooltip, Stack, Typography, Box, Skeleton } from "@mui/material";
import { useThumbzApi } from "src/services/thumbz-api";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "src/config/query-key";
import { IApprovalStage, ICustomer } from "src/services/thumbz-base-api";
import toast from "react-hot-toast";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import { useConfirm } from "material-ui-confirm";
import { CustomerUpsertForm } from "../customers/customer-upsert-form";
import NewCustomerAvatar from "src/components/avatar/NewCustomerAvatar";
import CustomerAvatarWithIcons from "src/components/avatar/CustomerAvatarWithIcons";

interface ApprovalCustomersAvatarProps {
  stage: IApprovalStage;
  wrk_id: string;
  wrk_slug: string;
}

export const ApprovalCustomersAvatar: React.FC<ApprovalCustomersAvatarProps> = ({
  stage,
  wrk_id,
  wrk_slug,
}) => {
  const [openCustomerTable, setOpenCustomerTable] = useState<boolean>(false);
  const [loadingCustomerId, setLoadingCustomerId] = useState<string | null>(null);
  const [selectedCustomers, setSelectedCustomers] = useState<Set<string>>(new Set());

  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { api } = useThumbzApi();

  const allCustomersQuery = useQuery({
    queryKey: queryKeys.customer.customerControllerList({ wrk_id }).queryKey,
    queryFn: () => api.customer.customerControllerList({ wrk_id }),
    enabled: !!wrk_id,
  });

  const selectedCustomersQuery = useQuery({
    queryFn: () => api.approvalStage.approvalStageControllerFind(stage.stg_id),
    enabled: !!stage.stg_id,
    queryKey: queryKeys.approvalStage.approvalStageControllerFind(stage.stg_id).queryKey,
  });

  useEffect(() => {
    if (selectedCustomersQuery.isSuccess) {
      const initialSelected = new Set(
        selectedCustomersQuery.data?.approvalStageCustomers.map(
          (stageCustomer) => stageCustomer.customer.cust_id,
        ),
      );
      setSelectedCustomers(initialSelected);
    }
  }, [selectedCustomersQuery.data?.approvalStageCustomers, selectedCustomersQuery.isSuccess]);

  const clearCache = () => {
    queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey.includes("approvalStage") && query.queryKey.includes(stage.stg_id),
    });

    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] === "customer" && query.queryKey.includes(wrk_id),
    });
  };

  const linkCustomerToStageMutation = useMutation({
    mutationFn: api?.approvalStage?.approvalStageControllerLink,
    onMutate: async ({ cust_id }) => {
      setLoadingCustomerId(cust_id);
      await queryClient.cancelQueries({
        queryKey: queryKeys.approvalStage.approvalStageControllerFind(stage.stg_id).queryKey,
      });
    },
    onSuccess: (_, { cust_id }) => {
      toast.success("Aprovador vinculado com sucesso");
      clearCache();
      setSelectedCustomers((prev) => new Set(prev).add(cust_id)); // 🔥 Adiciona o ícone de seleção
      setLoadingCustomerId(null);
      setOpenCustomerTable(false);
    },
    onError: (error) => {
      setLoadingCustomerId(null);
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message));
    },
  });

  const unlinkCustomerMutation = useMutation({
    mutationFn: api?.approvalStage?.approvalStageControllerUnlink,
    onMutate: async ({ cust_id }) => {
      setLoadingCustomerId(cust_id);
      await queryClient.cancelQueries({
        queryKey: queryKeys.approvalStage.approvalStageControllerFind(stage.stg_id).queryKey,
      });
    },
    onSuccess: (_, { cust_id }) => {
      toast.success("Aprovador removido com sucesso");
      clearCache();
      setSelectedCustomers((prev) => {
        const updated = new Set(prev);
        updated.delete(cust_id); // 🔥 Remove o ícone de seleção
        return updated;
      });
      setLoadingCustomerId(null);
    },
    onError: (error) => {
      setLoadingCustomerId(null);
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message));
    },
  });

  const handleToggleSelection = (customer: ICustomer, isSelected: boolean) => {
    if (isSelected) {
      unlinkCustomerMutation.mutate({
        stg_id: stage.stg_id,
        cust_id: customer.cust_id,
      });
    } else {
      linkCustomerToStageMutation.mutate({
        cust_id: customer.cust_id,
        stg_id: stage.stg_id,
      });
    }
  };

  if (allCustomersQuery.isLoading || selectedCustomersQuery.isLoading) {
    return <div>Carregando...</div>;
  }

  if (allCustomersQuery.isError || selectedCustomersQuery.isError) {
    return <div>Erro ao carregar os dados</div>;
  }

  return (
    <>
      <Box display="flex" flexWrap="wrap" gap={2}>
        {(allCustomersQuery.data ?? []).map((customer: ICustomer) => {
          const isSelected = selectedCustomers.has(customer.cust_id);
          const isLoading = loadingCustomerId === customer.cust_id;

          return (
            <Tooltip
              key={`customer-${customer.cust_id}`}
              title={
                <Stack direction="column">
                  <Typography variant="caption">{customer.cust_email}</Typography>
                  <Typography variant="caption">{customer.cust_mobile_phone}</Typography>
                </Stack>
              }
            >
              {isLoading ? (
                <Stack direction="column" alignItems="center" spacing={0.65} alignContent="center">
                  <Skeleton variant="circular" width={75} height={75} />
                  <Skeleton variant="text" width={50} />
                </Stack>
              ) : (
                <Box
                  onClick={() => handleToggleSelection(customer, isSelected)}
                  sx={{ cursor: "pointer" }}
                >
                  <CustomerAvatarWithIcons customer={customer} showIcon={isSelected} />
                </Box>
              )}
            </Tooltip>
          );
        })}
        <NewCustomerAvatar onClick={() => setOpenCustomerTable(true)} />
      </Box>

      <CustomerUpsertForm
        open={openCustomerTable}
        customer={null}
        wrk_id={wrk_id}
        onClose={() => setOpenCustomerTable(false)}
        onSuccess={(customer) =>
          linkCustomerToStageMutation.mutate({ cust_id: customer.cust_id, stg_id: stage.stg_id })
        }
        showSelect={false}
      />
    </>
  );
};

export default ApprovalCustomersAvatar;
