import React from "react";
import { useAppSelector } from "src/app/hooks";
import { approvalEditSlice } from "src/features/approval-edit/approvalEditSlice";
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Stack,
  useTheme,
  LinearProgress,
} from "@mui/material";
import { StatusRow } from "src/components/status/StatusRow";
import { CustomerRow } from "src/components/customer/customer-row";
import { StatusIcon } from "src/components/status/StatusIcon";
import { IStatusTypeStpTypeEnum } from "src/services/thumbz-base-api";
import { EmptyPlaceholder } from "../components/EmptyPlaceholder";
import { ApprovalEditChangeStatus } from "./approval-edit-change-status";
import { Skeleton } from "src/components/upload/skeleton";

interface IApprovalStageStepperProps {}

export const ApprovalStageStepper: React.FC<IApprovalStageStepperProps> = () => {
  const theme = useTheme();
  const allStages = useAppSelector(approvalEditSlice.selectors.allStages);
  const workspace = useAppSelector(approvalEditSlice.selectors.workspace);
  const currentStageIndex = useAppSelector(approvalEditSlice.selectors.currentStageIndex);
  const shouldShow = useAppSelector(approvalEditSlice.selectors.shouldShowStageStepper);
  const isStageCompleted = useAppSelector(approvalEditSlice.selectors.isStageCompleted);
  const stageCompletedPercentage = useAppSelector(
    approvalEditSlice.selectors.stageCompletedPercentage,
  );
  const status = useAppSelector(approvalEditSlice.selectors.status);
  const isLoading = status === "loading";

  if (!shouldShow) {
    return null;
  }

  return (
    <Skeleton isLoading={isLoading} props={{ width: "100%" }}>
      <Stack direction="column" spacing={4} alignItems="center" flex={1} flexGrow={1}>
        <Stepper
          sx={{
            width: "100%",
            flexGrow: 1,
            flex: 1,
          }}
          activeStep={currentStageIndex}
          orientation="vertical"
          style={{
            flex: 1,
            flexGrow: 1,
          }}
        >
          {allStages.map((stage, index) => {
            const completed = isStageCompleted(stage.stg_id);
            return (
              <Step key={index} completed={completed} expanded>
                <StepLabel
                  StepIconProps={{
                    completed,
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent={"space-between"}
                    spacing={1}
                  >
                    <Typography variant="body2">
                      {stage.stg_name || stage.stg_description}
                    </Typography>
                    <Typography variant="caption">
                      ({`${stageCompletedPercentage(stage.stg_id).toFixed(0)}%`})
                    </Typography>
                  </Stack>
                </StepLabel>
                <LinearProgress
                  variant="determinate"
                  color={completed ? "success" : "inherit"}
                  value={stageCompletedPercentage(stage.stg_id)}
                />
                {workspace?.wrk_slug ? (
                  <Stack flex={1} spacing={1}>
                    {stage.approvalStageCustomers.map((approvalStageCustomer) => (
                      <CustomerRow
                        key={`${approvalStageCustomer.asc_id}-${approvalStageCustomer.customer.cust_id}`}
                        wrk_slug={workspace?.wrk_slug}
                        approvalStageCustomer={approvalStageCustomer}
                      />
                    ))}
                  </Stack>
                ) : (
                  <EmptyPlaceholder overlineText="Nenhum workspace selecionado" />
                )}
              </Step>
            );
          })}
        </Stepper>
        {workspace?.wrk_id && <ApprovalEditChangeStatus wrk_id={workspace?.wrk_id} />}
      </Stack>
    </Skeleton>
  );
};
