import React, { useEffect, useState } from "react";
import { ColDef, ValueFormatterParams } from "ag-grid-community";
import {
  Box,
  Button,
  Tooltip,
  IconButton,
  Stack,
  Popover,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useThumbzApi } from "src/services/thumbz-api";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "src/config/query-key";
import { ICustomer } from "src/services/thumbz-base-api";
import toast from "react-hot-toast";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import { Add, Delete, Edit, MoreVertOutlined } from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";
import { AgGridReactDefault } from "src/components/table/custom-table";
import { CustomerUpsertForm } from "src/sections/customers/customer-upsert-form";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { RenderAvatar } from "src/components/avatar/RenderAvatar";
import { formatPhoneNumber } from "src/utils/format-phone-number";
import { is } from "date-fns/locale";

interface WorkspaceCustomersProps {
  wrk_id: string;
}

export const WorkspaceCustomers: React.FC<WorkspaceCustomersProps> = ({ wrk_id }) => {
  const [modalopen, setModalopen] = useState<boolean>(false);
  const confirm = useConfirm();
  const [rowData, setRowData] = useState<ICustomer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(null);
  const queryClient = useQueryClient();
  const { api } = useThumbzApi();

  function handleCustomerEdit(customer: ICustomer): void {
    setSelectedCustomer(customer);
    setModalopen(true);
  }

  const [colDefs] = useState<ColDef<ICustomer>[]>([
    {
      pinned: "left",
      headerName: "Avatar",
      field: "cust_photo.ast_url",
      editable: false,
      width: 80,
      cellRenderer: (params: {
        value: string | undefined;
        data: { cust_id: string | undefined | null };
      }) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="start"
          height="100%"
          alignContent={"center"}
        >
          <RenderAvatar
            sx={{
              height: 35,
              width: 35,
            }}
            src={params.value}
          />
        </Box>
      ),
    },
    {
      pinned: "left",
      headerName: "Nome",
      field: "cust_name",
      flex: 1,
    },
    {
      headerName: "Email",
      field: "cust_email",
      flex: 1,
    },
    {
      headerName: "Telefone",
      field: "cust_mobile_phone",
      valueFormatter: (params: ValueFormatterParams<ICustomer>) =>
        formatPhoneNumber({
          phoneNumber: params.value,
          countryCode: params?.data?.country_code || null,
        }),
    },
    // action to remove
    {
      headerName: "Ações",
      pinned: "right",
      width: 100,
      cellRenderer: (params: { data: ICustomer }) => (
        <PopupState variant="popover" popupId={`popup-menu-${params.data.cust_id}`}>
          {(popupState) => (
            <div>
              <IconButton {...bindTrigger(popupState)}>
                <MoreVertOutlined />
              </IconButton>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Menu
                  anchorEl={popupState.anchorEl}
                  open={Boolean(popupState.anchorEl)}
                  onClose={(event, reason) => popupState.close()}
                >
                  <MenuItem onClick={() => handleCustomerEdit(params.data)}>
                    <ListItemIcon>
                      <Edit fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Editar</ListItemText>
                  </MenuItem>

                  <MenuItem color="error" onClick={() => handleDelete(params.data)}>
                    <ListItemIcon>
                      <Delete fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Excluir</ListItemText>
                  </MenuItem>
                </Menu>
              </Popover>
            </div>
          )}
        </PopupState>
      ),
    },
  ]);

  function clearCache() {
    queryClient.invalidateQueries({
      queryKey: queryKeys.customer.customerControllerList({ wrk_id }).queryKey,
    });
  }

  const customersQuery = useQuery({
    queryKey: queryKeys.customer.customerControllerList({ wrk_id }).queryKey,
    queryFn: () => api.customer.customerControllerList({ wrk_id }),
    enabled: !!wrk_id,
  });

  const deleteCustomerMutation = useMutation({
    mutationFn: api.customer.customerControllerDelete,
    onSuccess: () => {
      clearCache();
      toast.success("Aprovador excluído com sucesso");
    },
    onError: (error) =>
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message)),
  });

  useEffect(() => {
    if (customersQuery.data) {
      setRowData(customersQuery.data);
    }
  }, [customersQuery.data]);

  const handleDelete = (customer: ICustomer) => {
    confirm({
      description: `Tem certeza que deseja excluir o aprovador ${customer.cust_name}?`,
      confirmationText: "Sim, excluir",
      confirmationButtonProps: {
        color: "error",
      },
    }).then(() => {
      deleteCustomerMutation.mutate({ cust_id: customer.cust_id });
    });
  };

  const handleAdd = () => {
    setSelectedCustomer(null);
    setModalopen(true);
  };

  function handleCustomerUpsertSuccess(customer: ICustomer): void {
    clearCache();
    setModalopen(false);
  }

  return (
    <Stack spacing={2}>
      <Stack justifyContent="flex-end" flex={1} direction="row">
        <Tooltip title="Novo aprovador">
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleAdd}
            startIcon={<Add />}
          >
            Novo aprovador
          </Button>
        </Tooltip>
      </Stack>

      <AgGridReactDefault<ICustomer>
        loading={customersQuery.isFetching}
        rowData={rowData}
        columnDefs={colDefs}
        rowSelection="single"
      />
      <CustomerUpsertForm
        key={selectedCustomer?.cust_id}
        open={modalopen}
        customer={selectedCustomer}
        wrk_id={wrk_id}
        showSelect={false}
        onClose={() => setModalopen(false)}
        onSuccess={handleCustomerUpsertSuccess}
      />
    </Stack>
  );
};
