import React from "react";
import { Stack, Typography, Chip, Box, Skeleton } from "@mui/material";
import { IApproval } from "src/services/thumbz-base-api";
import { approvalCreateSlice } from "src/features/approvalsCreate/approvalCreateSlice";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { ApprovalTypeFields } from "src/components/approval/approval-type-fields";
import { IHandleChange } from "src/components/approval/field-renderer";

interface ApprovalComplementProps {
  approval: IApproval;
}

export const ApprovalComplement: React.FC<ApprovalComplementProps> = ({ approval }) => {
  const approvalState = useAppSelector(approvalCreateSlice.selectors.approval);
  const isLoading = useAppSelector(approvalCreateSlice.selectors.isStep3Loading);
  const dispatch = useAppDispatch();

  const handleFieldChange = (props: IHandleChange) => {
    dispatch(
      approvalCreateSlice.actions.updateResponses({
        ...props,
        item_id: undefined,
      }),
    );
  };

  return (
    <Stack spacing={2}>
      <Stack>
        <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
          <Typography gutterBottom variant="h5">
            Complemento da Aprovação
          </Typography>
          <Chip variant="outlined" label={approval?.approvalType?.apt_name} />
        </Stack>
        <Typography variant="caption">{approval?.approvalType?.apt_description}</Typography>
      </Stack>

      {
        <Box display={isLoading ? "none" : "block"}>
          <ApprovalTypeFields
            approvalTypeFields={approval?.approvalType?.approvalTypeFields || []}
            onFieldChange={handleFieldChange}
            readOnly={isLoading}
            responses={
              approvalState?.responses?.map((r) => ({
                apr_value: r.apr_value,
                apv_id: approval.apv_id,
                atf_id: r.approvalTypeField.atf_id,
                apr_id: r.apr_id,
                item_id: undefined,
              })) || []
            }
          />
        </Box>
      }

      {isLoading && <Skeleton variant="rounded" height={200} />}
    </Stack>
  );
};
