import { Typography, Chip, Avatar, Button } from "@mui/material";
import { Stack, Box } from "@mui/system";
import React from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { approvalCreateSlice } from "src/features/approvalsCreate/approvalCreateSlice";
import { IApproval } from "src/services/thumbz-base-api";
import { RevisionGenericWrapper } from "./revision-generic-wrapper";

interface IRevisionStagesProps {
  approval: IApproval;
}

export const RevisionStages: React.FC<IRevisionStagesProps> = ({ approval }) => {
  const isLoading = useAppSelector(approvalCreateSlice.selectors.isStep4Loading);
  const dispatch = useAppDispatch();

  function _goToStep2() {
    dispatch(approvalCreateSlice.actions.changeStep(1));
  }

  return (
    <>
      <RevisionGenericWrapper
        title={"Etapas e Aprovadores"}
        description="Revise as etapas e aprovadores"
        action={
          <Button variant="text" color="primary" onClick={_goToStep2} disabled={isLoading}>
            Editar
          </Button>
        }
      >
        <Stack direction="row" spacing={2}>
          {approval?.stages.map(({ approvalStageCustomers, stg_name, stg_id }) => (
            <Box key={stg_id} flexGrow={1} p={1} border={1} borderColor="grey.300" borderRadius={1}>
              <Typography variant="body1" gutterBottom>
                {stg_name}
              </Typography>
              <Stack spacing={1}>
                {approvalStageCustomers.map(({ customer }) => (
                  <Stack key={customer.cust_id} direction="row" alignItems="center" spacing={1}>
                    <Chip
                      avatar={
                        <Avatar src={customer.cust_photo?.ast_url ?? undefined}>
                          {customer.cust_name.charAt(0)}
                        </Avatar>
                      }
                      label={customer.cust_name}
                    />
                  </Stack>
                ))}
                {approvalStageCustomers.length === 0 && (
                  <Typography variant="body2" color="textSecondary">
                    Nenhum aprovador
                  </Typography>
                )}
              </Stack>
            </Box>
          ))}
        </Stack>
      </RevisionGenericWrapper>
    </>
  );
};
