import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { StatusIcon } from "src/components/status/StatusIcon";
import { IApproval } from "src/services/thumbz-base-api";
import { RevisionGenericWrapper } from "./revision-generic-wrapper";

interface IRevisionDetailsProps {
  approval: IApproval;
}

export const RevisionDetails: React.FC<IRevisionDetailsProps> = ({ approval }) => {
  return (
    <Stack spacing={2} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
      <RevisionGenericWrapper title={"Nome da Aprovação"} description={approval?.apv_name} />
      <RevisionGenericWrapper title={"Status"}>
        <Stack direction={"row"} alignItems={"center"} spacing={1} flex={1}>
          {approval?.apv_status.statusType.stp_type && (
            <StatusIcon statusType={approval?.apv_status.statusType.stp_type} color={"#878787"} />
          )}
          <Typography variant="body1">{approval?.apv_status.sts_name}</Typography>
        </Stack>
      </RevisionGenericWrapper>
    </Stack>
  );
};
