import { useState, type FC } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { OrganizationCollaborator } from "./company-member";
import { Button, Skeleton } from "@mui/material";
import { queryKeys } from "src/config/query-key";
import { useQuery } from "@tanstack/react-query";
import { useThumbzApi } from "src/services/thumbz-api";
import { useMounted } from "src/hooks/use-mounted";
import { CompanyInvitation } from "./company-invitation";
import { useOrganization } from "src/contexts/organization/OrganizationContext";
import { OrganizationInviteByEmailModal } from "./workspace-invite-by-email";
import { OrganizationUsage } from "src/components/usage/organization-usage";
import { EmptyPlaceholder } from "src/sections/components/EmptyPlaceholder";

type CompanyTeamProps = object;

export const CompanyTeam: FC<CompanyTeamProps> = () => {
  const isMounted = useMounted();
  const { api } = useThumbzApi();
  const [open, setOpen] = useState<boolean>(false);
  const { selectedOrganization } = useOrganization();

  const org_id = String(selectedOrganization?.org_id);

  const collaboratorsQuery = useQuery({
    queryKey: queryKeys.organization.collaborators({
      org_id,
    }).queryKey,
    queryFn: () => api.organization.organizationControllerGetCollaborators(org_id),
    enabled: !!org_id && isMounted(),
  });

  const invitationsQuery = useQuery({
    queryKey: queryKeys.organization.invitations({
      org_id,
    }).queryKey,
    queryFn: () => api.organization.organizationControllerGetInvitations(org_id),
    enabled: !!org_id && isMounted(),
  });

  const members = collaboratorsQuery.data || [];
  const invitations = invitationsQuery.data || [];

  const InviteButton = () => (
    <Button
      color="primary"
      disabled={collaboratorsQuery.isLoading}
      onClick={() => setOpen(true)}
      variant="contained"
    >
      Convidar
    </Button>
  );

  return (
    <>
      <Stack spacing={3}>
        <OrganizationUsage type="collaborators" />
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
          <Typography variant="h6">Equipe ({members.length})</Typography>
          <InviteButton />
        </Stack>
        <div>
          <Grid container spacing={3}>
            {collaboratorsQuery.isLoading && (
              <>
                <Grid item sm={6}>
                  <Skeleton variant="rectangular" width="100%" height={150} />
                </Grid>
                <Grid item sm={6}>
                  <Skeleton variant="rectangular" width="100%" height={150} />
                </Grid>
              </>
            )}
            {members.map((member) => (
              <Grid item key={member.ocl_id} xs={12} sm={6}>
                <OrganizationCollaborator member={member} org_id={org_id} members={members}/>
              </Grid>
            ))}
          </Grid>
        </div>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">Convites ({invitations.length})</Typography>
        </Stack>
        <div>
          <Grid container spacing={3}>
            {invitationsQuery.isLoading && (
              <>
                <Grid item sm={6}>
                  <Skeleton variant="rectangular" width="100%" height={150} />
                </Grid>
                <Grid item sm={6}>
                  <Skeleton variant="rectangular" width="100%" height={150} />
                </Grid>
              </>
            )}
            {invitations.length === 0 && !invitationsQuery.isLoading && (
              <Grid item xs={12}>
                <EmptyPlaceholder
                  overlineText="Sem convites"
                  customSubtitle="Nenhum convite foi enviado para colaboradores."
                  showButton={false}
                >
                  <InviteButton />
                </EmptyPlaceholder>
              </Grid>
            )}
            {invitations.map((invitation) => (
              <Grid item key={invitation.orgi_id} xs={12} sm={6}>
                <CompanyInvitation invitation={invitation} org_id={org_id} />
              </Grid>
            ))}
          </Grid>
        </div>
      </Stack>
      {selectedOrganization && (
        <OrganizationInviteByEmailModal
          organization={selectedOrganization}
          open={open}
          setOpen={setOpen}
        />
      )}
    </>
  );
};