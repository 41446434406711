import React, { useMemo, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Divider,
  Typography,
  Select,
  Skeleton,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ICustomer, ICustomerUpsertDto } from "src/services/thumbz-base-api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import { useThumbzApi } from "src/services/thumbz-api";
import { queryKeys } from "src/config/query-key";
import PhotoForm from "src/components/upload/photo-form";
import { Box } from "@mui/system";
import { isPhotoBase64 } from "src/utils/is-base-64";
import PhoneNumberInput from "src/components/form/PhoneNumberInput";
import { MuiTelInputInfo } from "mui-tel-input";
import { PhoneNumberUtil } from "google-libphonenumber";
import { LoadingButton } from "@mui/lab";

interface CustomerUpsertFormProps {
  open: boolean;
  customer: Partial<ICustomer> | null;
  wrk_id: string;
  onClose: () => void;
  onSuccess: (customer: ICustomer) => void;
  showSelect?: boolean;
}

interface ICustomerForm extends Omit<ICustomerUpsertDto, "wrk_id"> {
  base64?: string;
}

const phoneUtil = PhoneNumberUtil.getInstance();

export const CustomerUpsertForm: React.FC<CustomerUpsertFormProps> = ({
  open,
  customer,
  wrk_id,
  onClose,
  onSuccess,
  showSelect = true,
}) => {
  const [countryCode, setCountryCode] = useState<MuiTelInputInfo | null>(null);
  const { api } = useThumbzApi();
  const queryClient = useQueryClient();
  const initialvalues: ICustomerForm = useMemo(
    () => ({
      cust_email: customer?.cust_email || "",
      cust_id: customer?.cust_id || null,
      cust_mobile_phone: customer?.cust_mobile_phone || "",
      cust_name: customer?.cust_name || "",
      country_calling_code: customer?.country_calling_code || "",
      base64: customer?.cust_photo?.ast_url || "",
      country_code: customer?.country_code || "",
    }),
    [customer],
  );

  const allCustomers = useQuery({
    queryFn: () => api?.customer?.customerControllerList({ wrk_id }),
    queryKey: queryKeys.customer.customerControllerList({ wrk_id }).queryKey,
  });

  const changeCustomerPhotoMutation = useMutation({
    mutationFn: api?.customer?.customerControllerUpdatePhoto,
    onSuccess: (res) => handleSuccess(res),
    onError: (error) =>
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message)),
  });

  const upsertCustomerMutation = useMutation({
    mutationFn: api?.customer?.customerControllerUpsert,
    onSuccess: (res) => {
      if (isPhotoBase64(formik.values.base64))
        return changeCustomerPhotoMutation.mutate({
          base64: String(formik.values.base64),
          cust_id: res.cust_id,
        });
      else handleSuccess(res);
    },
    onError: (error) =>
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message)),
  });

  const validationSchema = Yup.object().shape({
    cust_name: Yup.string().required("Nome é obrigatório"),
    cust_email: Yup.string().email("Email inválido").required("Email é obrigatório"),
    cust_mobile_phone: Yup.string()
      .required("Telefone é obrigatório")
      .test("valid-phone", "Telefone inválido", function (value) {
        try {
          const parsedNumber = phoneUtil.parseAndKeepRawInput(
            value || "",
            countryCode?.countryCode || "",
          );
          return phoneUtil.isValidNumber(parsedNumber);
        } catch {
          return false;
        }
      }),
  });

  const formik = useFormik<ICustomerForm>({
    initialValues: initialvalues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      upsertCustomerMutation.mutate({
        cust_email: values.cust_email,
        cust_id: values.cust_id,
        cust_mobile_phone: values.cust_mobile_phone,
        cust_name: values.cust_name,
        country_calling_code: values.country_calling_code,
        country_code: countryCode?.countryCode || "",
        wrk_id: wrk_id,
      });
    },
  });

  function handleSuccess(customer: ICustomer) {
    formik.resetForm();

    toast.success("Aprovador salvo com sucesso");

    queryClient.invalidateQueries({
      queryKey: queryKeys.customer.customerControllerList({
        wrk_id,
      }).queryKey,
    });
    queryClient.invalidateQueries({
      queryKey: queryKeys.customer.customerControllerFind(customer.cust_id).queryKey,
    });

    if (onSuccess) {
      onSuccess(customer);
    }
  }

  useEffect(() => {
    if (JSON.stringify(formik.values) !== JSON.stringify(initialvalues)) {
      formik.setValues(initialvalues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    formik.setFieldValue("country_calling_code", countryCode?.countryCallingCode || "");
    formik.setFieldValue("country_code", countryCode?.countryCode || "");
    formik.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Stack spacing={2}>
          {showSelect && (
            <Stack spacing={2}>
              <Select
                fullWidth
                native
                value={undefined}
                onChange={(e) => {
                  const _customer = allCustomers.data?.find(
                    (customer) => customer.cust_id === e.target.value,
                  );

                  if (_customer && onSuccess) {
                    onSuccess(_customer);
                  }
                }}
              >
                <option key={0} value={undefined}>
                  Selecione um aprovador
                </option>
                {allCustomers.data?.map((customer) => (
                  <option key={customer.cust_id} value={customer.cust_id}>
                    {`${customer.cust_name} - ${customer.cust_email}`}
                  </option>
                ))}
              </Select>

              <Divider>Ou</Divider>
            </Stack>
          )}

          {showSelect && (
            <Typography variant="h6" color="textSecondary">
              Cadastre um novo aprovador
            </Typography>
          )}
          <form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <Box display={upsertCustomerMutation.isPending ? "none" : "block"}>
                <PhotoForm
                  key={`${formik.values.base64}-${Date.now()}`}
                  loading={changeCustomerPhotoMutation.isPending}
                  default_photo_url={formik.values.base64}
                  onFormSuccessSubmit={({ base64 }) => {
                    formik.setFieldValue("base64", base64);
                  }}
                />
              </Box>
              {upsertCustomerMutation.isPending && (
                <Skeleton variant="rectangular" width="50" height={100} />
              )}

              <Box my={2}></Box>

              <TextField
                autoFocus
                margin="dense"
                label="Nome"
                type="text"
                fullWidth
                {...formik.getFieldProps("cust_name")}
                error={Boolean(formik.errors.cust_name) && formik.touched.cust_name}
                helperText={formik.errors.cust_name}
                disabled={upsertCustomerMutation.isPending}
              />
              <TextField
                margin="dense"
                label="Email"
                type="email"
                placeholder="jose@gmail.com"
                fullWidth
                {...formik.getFieldProps("cust_email")}
                error={Boolean(formik.errors.cust_email) && formik.touched.cust_email}
                helperText={formik.errors.cust_email}
                disabled={upsertCustomerMutation.isPending}
              />
              <PhoneNumberInput
                field={formik.getFieldProps("cust_mobile_phone")}
                form={formik}
                label="Telefone"
                meta={formik.getFieldMeta("cust_mobile_phone")}
                key={"cust_mobile_phone"}
                setCountryCode={setCountryCode}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary" disabled={upsertCustomerMutation.isPending}>
                Cancelar
              </Button>
              <LoadingButton
                type="submit"
                color="primary"
                loading={upsertCustomerMutation.isPending}
              >
                Salvar
              </LoadingButton>
            </DialogActions>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
