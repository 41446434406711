import type { FC } from "react";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Scrollbar } from "../scrollbar";
import { Approval } from "@mui/icons-material";
import { IDashboardResponseDto, IStatusTypeStpTypeEnum } from "src/services/thumbz-base-api";
import { CustomColorPill } from "../custom-color-pill";
import { COLORS_CHART } from "./dashboard-total-approvals";
import { UseQueryResult } from "@tanstack/react-query";

interface IProps {
  query: UseQueryResult<IDashboardResponseDto, Error>;
}

export const DashboardLastApprovals: FC<IProps> = ({ query }) => {
  if (query.isLoading) {
    // return fake table of Skeletons:
    return (
      <Card>
        <CardHeader
          title={<Skeleton variant="text" width={100} height={20} />}
          subheader={<Skeleton variant="text" width={150} height={15} />}
        />
        <Scrollbar>
          <Box sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Aprovação</TableCell>
                  <TableCell>Aprovador</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Comentários</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[0, 1, 2, 3].map((index) => (
                  <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Avatar sx={{ mr: 2 }}>
                          <Approval fontSize="small" />
                        </Avatar>
                        <Typography variant="subtitle2">
                          <Skeleton variant="text" width={100} />
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        <Skeleton variant="text" width={100} />
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <CustomColorPill color={COLORS_CHART.NOT_STARTED}>
                        <Skeleton variant="text" width={100} />
                      </CustomColorPill>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        <Skeleton variant="text" width={100} />
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
      </Card>
    );
  }
  return (
    <Card>
      <CardHeader
        title={
          query?.data?.lastApprovals.title ?? <Skeleton variant="text" width={100} height={20} />
        }
        subheader={
          query?.data?.lastApprovals.description ?? (
            <Skeleton variant="text" width={150} height={15} />
          )
        }
      />
      <Scrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Aprovação</TableCell>
                <TableCell>Workspace</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Tipo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {query?.data?.lastApprovals.approvals.map(
                ({ apt_name, apv_name, sts_color, sts_name, wrk_id, wrk_name }, index) => (
                  <TableRow
                    key={`last-${index}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        {/* <Avatar sx={{ mr: 2 }}>
                          <Approval fontSize="small" />
                        </Avatar> */}
                        <Typography variant="subtitle2">{apv_name}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{wrk_name}</Typography>
                    </TableCell>
                    <TableCell>
                      <CustomColorPill color={sts_color as IStatusTypeStpTypeEnum}>
                        {sts_name}
                      </CustomColorPill>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{apt_name}</Typography>
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Card>
  );
};
