import React from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { dashboardSlice } from "src/features/dashboard/dashboardSlice";
import { Box, TextField, Typography } from "@mui/material";

interface IDateRangeSelectorProps {}

export const DateRangeSelector: React.FC<IDateRangeSelectorProps> = () => {
  const dispatch = useAppDispatch();
  const startDate = useAppSelector(dashboardSlice.selectors.selectedStartDate);
  const endDate = useAppSelector(dashboardSlice.selectors.selectedEndDate);

  const [startValue, setStartValue] = React.useState<string>(
    startDate ? startDate.split("T")[0] : "",
  );
  const [endValue, setEndValue] = React.useState<string>(endDate ? endDate.split("T")[0] : "");

  function handleStartDateChange(event: React.ChangeEvent<HTMLInputElement>) {
    const date = event.target.value;
    setStartValue(date);
    dispatch(dashboardSlice.actions.setStartDate(new Date(date).toISOString()));
  }

  function handleEndDateChange(event: React.ChangeEvent<HTMLInputElement>) {
    const date = event.target.value;
    setEndValue(date);
    dispatch(dashboardSlice.actions.setEndDate(new Date(date).toISOString()));
  }

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <TextField
        label="Data Inicial"
        type="date"
        value={startValue}
        onChange={handleStartDateChange}
        fullWidth
      />
      <TextField
        label="Data Final"
        type="date"
        value={endValue}
        onChange={handleEndDateChange}
        fullWidth
        inputProps={{
          min: startValue, // Impede selecionar data final menor que a inicial
        }}
      />
    </Box>
  );
};
