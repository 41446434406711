import { IOrganizationCollaborator } from "src/services/thumbz-base-api";

interface UseUserRoleReturn {
  loggedUserRole: string | null;
  isTheSameUser: boolean;
  isOwner: boolean;
}

export function useUserRole(
  members: IOrganizationCollaborator[] | null,
  loggedUserId: string | null,
  memberId: string
): UseUserRoleReturn {
  // Identify the role of the logged in user within the organization
  const loggedUserRole = members
    ? members.find((member) => member.user.usu_id === loggedUserId)?.role?.role_constant ?? null
    : null;

  // Check if the logged in user is the same as the provided member
  const isTheSameUser = loggedUserId === memberId;

  // Check if the logged in user is OWNER
  const isOwner = loggedUserRole === "OWNER";

  return {
    loggedUserRole,
    isTheSameUser,
    isOwner,
  };
}
