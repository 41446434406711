import { ReactNode, useState, type FC } from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import { IOrganizationInvitationLinkDto } from "src/services/thumbz-base-api";
import { getInitials } from "src/utils/get-initials";
import { formatDistanceToNow } from "date-fns";
import {
  Tooltip,
  Chip,
  Button,
  Menu,
  MenuItem,
  Divider,
  alpha,
  MenuProps,
  styled,
  CircularProgress,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useThumbzApi } from "src/services/thumbz-api";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import toast from "react-hot-toast";
import { ConfirmModal } from "src/components/modal/confirm-modal";
import { queryKeys } from "src/config/query-key";
import { ptBR } from "date-fns/locale";
import { ArrowDropDown, CancelOutlined, CopyAll, EmailOutlined } from "@mui/icons-material";

interface CompanyInvitationProps {
  invitation: IOrganizationInvitationLinkDto;
  org_id: string;
}

interface MenuOption {
  label: string;
  icon: ReactNode;
  action: () => Promise<boolean>;
}

interface DropdownMenuButtonProps {
  buttonLabel: string;
  menuOptions: {
    section: string;
    options: MenuOption[];
  }[];
}

const DropdownMenuButton: React.FC<DropdownMenuButtonProps> = ({ buttonLabel, menuOptions }) => {
  const [isLoading, setIsLoading] = useState<{
    [key: string]: boolean;
  }>({
    cancel: false,
    resend: false,
    copyLink: false,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button endIcon={<ArrowDropDown />} onClick={handleClick}>
        {buttonLabel}
      </Button>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuOptions.map(({ options, section }, index) => (
          <>
            {index > 0 && <Divider sx={{ my: 0.5 }} />}
            <Typography color="text.secondary" variant="caption" sx={{ px: 2, py: 1 }}>
              {section}
            </Typography>
            {options.map(({ label, action, icon }) => (
              <MenuItem
                disabled={isLoading[label]}
                key={label}
                onClick={async () => {
                  setIsLoading({ ...isLoading, [label]: true });
                  await action().finally(() => {
                    setIsLoading({ ...isLoading, [label]: false });
                    return handleClose();
                  });
                }}
              >
                {isLoading[label] ? (
                  <Box mr={2}>
                    <CircularProgress size={12} />
                  </Box>
                ) : (
                  icon
                )}
                {label}
              </MenuItem>
            ))}
          </>
        ))}
      </StyledMenu>
    </>
  );
};

export default DropdownMenuButton;

export const CompanyInvitation: FC<CompanyInvitationProps> = ({ invitation, org_id }) => {
  const [open, setOpen] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { api } = useThumbzApi();
  const { orgi_email, orgi_id, orgi_accepted, orgi_created_at } = invitation;
  const dateString = formatDistanceToNow(new Date(orgi_created_at), {
    addSuffix: true,
    locale: ptBR,
  });
  // TODO: add role
  // const { isAdmin } = useOrganization({ org_id, usu_id: user?.usu_id });

  const mutation = useMutation({
    mutationFn: api.organization.organizationControllerCancelInvite,
    onSuccess() {
      toast.success("Invitation canceled");
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) =>
          queryKeys.organization
            .invitations({ org_id })
            .queryKey.find((key) => queryKey.includes(key)) !== undefined,
      });
      setOpen(false);
    },
    onError(error) {
      const errors = getServerErrorArrayMessage(error);
      errors.forEach((error) => toast.error(error));
    },
  });

  const resendEmailMutation = useMutation({
    mutationFn: api.organization.organizationControllerInviteResend,
    onSuccess() {
      toast.success("Email reenviado com sucesso!");
    },
    onError(error) {
      const errors = getServerErrorArrayMessage(error);
      errors.forEach((error) => toast.error(error));
    },
  });

  async function handleCancel() {
    await mutation.mutateAsync({ org_id, orgi_id });
  }

  async function handleResendEmail(orgi_invitation_code: string) {
    const response = await resendEmailMutation.mutateAsync({ org_id, orgi_invitation_code });
    return !!response;
  }

  async function handleCopyLink(invitation: IOrganizationInvitationLinkDto) {
    navigator.clipboard.writeText(invitation.invitation_link);
    toast.success("Link copiado com sucesso!");
    return true;
  }

  const menuOptions: DropdownMenuButtonProps["menuOptions"] = [
    {
      section: "Ações",
      options: [
        { label: "Copiar Link", action: () => handleCopyLink(invitation), icon: <CopyAll /> },
        {
          label: "Reenviar email",
          action: () => handleResendEmail(invitation.orgi_invitation_code),
          icon: <EmailOutlined />,
        },
      ],
    },
    {
      section: "Cancelar",
      options: [
        {
          label: "Cancelar Convite",
          action: () => {
            setOpen(true);
            return Promise.resolve(true);
          },
          icon: <CancelOutlined />,
        },
      ],
    },
  ];

  return (
    <>
      <Box
        sx={{
          borderColor: "divider",
          borderRadius: 1,
          borderStyle: "solid",
          borderWidth: 1,
          px: 3,
          py: 4,
        }}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          <Avatar>{getInitials(orgi_email)}</Avatar> {/* Adaptado para usar iniciais do email */}
          <div>
            <Typography variant="subtitle2">{orgi_email}</Typography>
            <Typography color="text.secondary" variant="body2">
              <Typography color="text.secondary" variant="body2">
                {dateString}
              </Typography>
            </Typography>
          </div>
        </Stack>

        <Stack alignItems="center" direction="row" flexWrap="wrap" spacing={1} sx={{ mt: 2 }}>
          <Tooltip title={"Isto é para saber se o usuário aceitou o convite"} placement="top">
            <Chip key={"Aceito"} label={orgi_accepted ? "Aceito" : "Pendente"} size="small" />
          </Tooltip>
          <DropdownMenuButton buttonLabel="Ações" menuOptions={menuOptions} />
          {/* TODO: habilitar botão baseado na função */}
          {/* {!orgi_accepted && (
            <LoadingButton
              variant="text"
              color="secondary"
              size="small"
              onClick={() => setOpen(true)}
            >
              Cancelar Convite
            </LoadingButton>
          )} */}

          {/* <Button onClick={() => handleCopyLink(invitation)}>Copiar link</Button> */}
          {/* <LoadingButton
            loading={resendEmailMutation.isPending}
            onClick={() => handleResendEmail(invitation.orgi_invitation_code)}
          >
            Reenviar email
          </LoadingButton> */}
        </Stack>
      </Box>
      <ConfirmModal
        open={open}
        setOpen={setOpen}
        description="Você tem certeza que deseja cancelar este convite?"
        confirmText="Cancelar Convite"
        onConfirm={() => handleCancel()}
        isPending={mutation.isPending}
        onSuccessful={() => {}}
      />
    </>
  );
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));
