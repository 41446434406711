import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { startOfMonth, endOfMonth, format } from "date-fns";

interface DashboardState {
  startDate: string;
  endDate: string;
}

const initialState: DashboardState = {
  startDate: format(startOfMonth(new Date()), "yyyy-MM-dd"),
  endDate: format(endOfMonth(new Date()), "yyyy-MM-dd"),
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setStartDate: (state, action: PayloadAction<string>) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      state.endDate = action.payload;
    },
  },
  selectors: {
    selectedStartDate: (state) => state.startDate,
    selectedEndDate: (state) => state.endDate,
  },
  extraReducers: () => {},
});

export const dashboardThunks = {};
