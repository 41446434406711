import { useState } from "react";
import {
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Skeleton,
  Stack,
  Grid,
  Button,
  Tooltip,
} from "@mui/material";
import { ApprovalTable } from "./approval-table";
import { ApprovalKanban } from "./approval-view-kanban";
import { IApproval, IApprovalListResponseDto } from "src/services/thumbz-base-api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "src/config/query-key";
import { useThumbzApi } from "src/services/thumbz-api";
import { useRouter } from "src/hooks/use-router";
import { useConfirm } from "material-ui-confirm";
import toast from "react-hot-toast";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import { Add, List, ViewKanbanOutlined } from "@mui/icons-material";
import { ApprovalUpsertForm } from "./approval-upsert-form";
import useLocalStorage from "src/hooks/use-local-storage";
import { useAppSelector } from "src/app/hooks";
import { organizationSlice } from "src/features/organization/organizationSlice";

type IViewMode = "table" | "kanban";

export type IApprovalViewAction = ({ apv_id, wrk_id }: { apv_id: string; wrk_id: string }) => void;

export interface IApprovalViewConfig {
  show_apv_name: boolean;
  show_apv_created_at: boolean;
  show_stages: boolean;
  show_workspace: boolean;
}

export interface ApprovalViewProps {
  onDelete: IApprovalViewAction;
  onOpen: IApprovalViewAction;
  approvals: IApprovalListResponseDto[];
  config: IApprovalViewConfig;
  org_id: string;
  refetchApprovals: () => void;
}

export const ApprovalViewSelector: React.FC<{
  wrk_id: string | undefined;
  config: IApprovalViewConfig;
  enableCreate?: boolean;
}> = ({ enableCreate = true, ...props }) => {
  const { org_id } = useAppSelector(organizationSlice.selectors.selectedOrganization);
  const [modalopen, setModalopen] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const router = useRouter();
  const { api } = useThumbzApi();
  const wrk_id = props.wrk_id || undefined;
  const [viewMode, setViewMode] = useLocalStorage<IViewMode>(`viewMode-${wrk_id}`, "kanban");

  function clearCache() {
    queryClient.invalidateQueries({
      queryKey: queryKeys.approval.approvalControllerList({ wrk_id: wrk_id || undefined, org_id })
        .queryKey,
    });
  }

  const deleteApprovalMutation = useMutation({
    mutationFn: api.approval.approvalControllerDelete,
    onSuccess: () => {
      clearCache();
      toast.success("Aprovação excluída com sucesso");
    },
    onError: (error) =>
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message)),
  });

  const handleViewChange = (event: React.MouseEvent<HTMLElement>, newView: IViewMode) => {
    if (newView) setViewMode(newView);
  };

  const handleOpen: IApprovalViewAction = ({ apv_id, wrk_id }) => {
    router.navigate({
      to: "/workspaces/$wrk_id/aprovacao/$apv_id",
      params: { wrk_id, apv_id },
    });
  };

  const handleDelete = (apv_id: string) => {
    confirm({
      description: "Tem certeza que deseja excluir essa aprovação?",
      confirmationText: "Sim, excluir",
      confirmationButtonProps: {
        color: "error",
      },
    }).then(() => {
      deleteApprovalMutation.mutate({ apv_id });
      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes(apv_id);
        },
      });
      // clear all cache that contains the key: "approval"
      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.includes("approval");
        },
      });
    });
  };

  const handleAdd = () => {
    setModalopen(true);
  };

  const approvalsQuery = useQuery({
    queryKey: queryKeys.approval.approvalControllerList({ wrk_id, org_id }).queryKey,
    queryFn: () => api.approval.approvalControllerList({ wrk_id, org_id }),
  });
  
  const refetchApprovals = approvalsQuery.refetch;

  function handleOnSave({ apv_id }: Partial<IApproval>): void {
    if (!apv_id) return;

    router.navigate({
      from: "/workspaces/$wrk_id",
      to: "/workspaces/$wrk_id/aprovacao/$apv_id",
      params: { wrk_id, apv_id },
    });
  }

  if (approvalsQuery.isLoading && viewMode === "table") {
    return (
      <Stack spacing={2}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
          <Skeleton key={`skeleton-table-${item}`} variant="rectangular" height={50} />
        ))}
      </Stack>
    );
  }

  if (approvalsQuery.isLoading && viewMode === "kanban") {
    return (
      <Grid container spacing={2}>
        {[1, 2, 3, 4].map((item) => (
          <Grid item key={`skeleton-kanban-${item}`} xs={12} sm={3} md={3}>
            <Skeleton variant="rectangular" height={"70vh"} />
          </Grid>
        ))}
      </Grid>
    );
  }

  if (approvalsQuery.isError) {
    return <div>Error</div>;
  }

  return (
    <>
      <Box>
        <Stack direction="row" spacing={2} py={2} justifyContent="space-between" mb={1}>
          <ToggleButtonGroup value={viewMode} exclusive onChange={handleViewChange} size="small">
            <ToggleButton value="kanban">
              <Tooltip title="Kanban">
                <ViewKanbanOutlined />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="table">
              <Tooltip title="Tabela">
                <List />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>

          {enableCreate && (
            <Tooltip title="Aprove conteúdos, arquivos, documentos e tudo que precisar de forma segura e organizada">
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleAdd}
                startIcon={<Add />}
              >
                Aprovação
              </Button>
            </Tooltip>
          )}
        </Stack>

        <Box>
          {viewMode === "table" ? (
            <ApprovalTable
              key={`table-${wrk_id}`}
              onDelete={({ apv_id }) => handleDelete(apv_id)}
              onOpen={handleOpen}
              config={props.config}
              approvals={approvalsQuery.data || []}
              org_id={org_id}
              refetchApprovals={refetchApprovals} 
            />
          ) : (
            <ApprovalKanban
            key={`kanban-${wrk_id}`}
            onDelete={({ apv_id }) => handleDelete(apv_id)}
            onOpen={handleOpen}
            config={props.config}
            approvals={approvalsQuery.data || []}
            org_id={org_id}
            refetchApprovals={refetchApprovals} 
          />
          )}
        </Box>
      </Box>
      {wrk_id && (
        <ApprovalUpsertForm
          open={modalopen}
          approval={undefined}
          wrk_id={wrk_id}
          onClose={() => setModalopen(false)}
          onSave={handleOnSave}
        />
      )}
    </>
  );
};
