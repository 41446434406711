import { Skeleton as MuiSkeleton, SkeletonOwnProps } from "@mui/material";
import React from "react";

interface ISkeletonProps {
  isLoading: boolean;
  children: React.ReactNode;
  props?: SkeletonOwnProps;
}

export const Skeleton: React.FC<ISkeletonProps> = ({ isLoading, children, props }) => {
  if (!isLoading) {
    return <>{children}</>;
  }

  return <MuiSkeleton {...props}>{children}</MuiSkeleton>;
};
